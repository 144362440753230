//import * as React from "react";
import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Titlestrip from "../../utility/Titlestrip";
import editIcon from "../../assets/svg/edit.svg";

import "../../assets/scss/company-list.scss";
import "../../assets/scss/payment-type.scss";
import "../../assets/scss/search.scss";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
/* import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
 */
import Loader from "../../components/loading/loader";
import { v4 as uuidv4 } from "uuid";
import Pagination from "react-js-pagination";
import configRoutes from "../../config/Routes";

import { debounce } from "lodash"
import { useDispatch, useSelector } from "react-redux";
import { clearState, userSelector } from "../../store/slices/user";
import { getReservations, getReservationsDateWise } from "../../external_api/Reservation";

import { paymentTypeList, paymentTypeAllList } from "../../constants/StaticData";

import moment from "moment";
import { format } from "path";
import CancelBooking from "../../components/booking/CancelBooking";

import DateRangePickerComp from "../../components/common/DateRangePickerComp"

import { downloadFile, getPaymentTypeName } from "../../utility/Helpers"

import { ExportCSV } from "../../components/Excel/ExportCSV"
import toast, { Toaster } from 'react-hot-toast';
import { AppDispatch } from "../../store";





const Reservation = () => {

    const { isFetching, isSuccess: isLogin, isError, errorMessage,company: userCompanyInfo, role: userRoleInfo } = useSelector(
        userSelector
    );

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    if (!isLogin) {
        navigate("/");
    }

    const [loading, setLoading] = useState(false);
    const [pageEntries, setpageEntries] = useState(30);

    //const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [rates, setRates] = useState([]);
    
    const [reservations, setReservations] = useState([]);
    
    const [cancelRerservationId, setCancelRerservationId] = useState("");
    

    const [current_page, setCurrentPage] = useState(1);
    const [per_page, setPerPage] = useState(0);
    const [total, setTotal] = useState(0);

    // Filter Inputs
    const [sort, setSort] = useState("created_at");
    const [order, setOrder] = useState("desc");
    const [searchTerm, setSearchTerm] = useState("");

    const columns = [
        { label: "Creation", accessor: "created_at", sortable: true },
        { label: "Company", accessor: "company", sortable: true },
        { label: "PNR", accessor: "reservation_id", sortable: true },
        { label: "Travellers", accessor: "first_name", sortable: true },
        { label: "From date", accessor: "pickup_date", sortable: true },
        { label: "Pick up location / Return location", accessor: "pickup_location", sortable: true },
        { label: "Payment Type", accessor: "payment_type", sortable: true },
        { label: "Type Reservation", accessor: "reservation_type", sortable: true },
        { label: "Group", accessor: "vehicle_group", sortable: true },
        { label: "Total", accessor: "total_amount", sortable: false },
        { label: "Status", accessor: "status", sortable: true },
    ];


    const getReservationDataHandler = async (pageNumber: number = 1, pageSize?: number, search?: string) => {
        setLoading(true);
        console.log("pageEntries", pageEntries)
        if (!pageSize) {
            pageSize = pageEntries;
        }
        if (!search) {
            search = searchTerm;
        }
        //console.log("searchTerm getUserDataHandler:",searchTerm);
        const response = await getReservations(pageNumber, pageSize, sort, order, search, selectedDate);
        console.log("response", response)
        const { data, status, errorMessage } = response;
        if (response.status == 200) {
            /*setReservations(response.data.data);
            setCurrentPage(response.data.meta.current_page);
            setPerPage(response.data.meta.per_page);
            setTotal(response.data.meta.total);*/
            setReservations(data.data);
            setCurrentPage(data.meta.current_page);
            setPerPage(data.meta.per_page);
            setTotal(data.meta.total);
            
            //setDownloadData(response.data.data);

            setLoading(false);
            document.getElementById('closeDatePicker')?.click();
        } else {
            setLoading(false);
            if (errorMessage === "Unauthenticated.") {
                //toast.error("Your session expired. Please login again.");
                console.log("Your session expired. Please login again.");
                setTimeout(function(){
                    dispatch(clearState());
                    localStorage.clear();
                },1000)
              
              
            } else {
              toast.error(errorMessage);
            }
        }
        
    }

    const [reloadPage, setReloadPage] = useState(false);

     useEffect(() => {
        console.log("mounting");
        getReservationDataHandler()
        
    }, [searchTerm]);
 

    const handlePageEntries = (pageEntryNumber: any) => {
        setpageEntries(pageEntryNumber.target.value);
        getReservationDataHandler(1, pageEntryNumber.target.value);
    };


    const handleSortingChange = (accessor: string) => {
        const sortOrder =
            accessor === sort && order === "asc" ? "desc" : "asc";
        setSort(accessor);
        setOrder(sortOrder);
        getReservationDataHandler();
    };

    const launchSearch = async (searchValue: string): Promise<any> => {
        /* if (searchValue.length < 3) {
          return;
        } */
        setSearchTerm(searchValue);

    };
    const delayedSearch = useCallback(

        debounce(async (searchValue) => {

            await launchSearch(searchValue);
        }, 1000),
        []
    );

    const [vPopup, setVPopup] = useState(false);
    const closeHandler = () => {
        setVPopup(false);
    };
    const loaderHandler = (load:boolean) => {
        setLoading(load);
        if(!load) {
            getReservationDataHandler(current_page);
        }
        
    };

    useEffect(() => {
        if(cancelRerservationId) {
            setVPopup(true);
            
        }
        
    }, [cancelRerservationId]);

   
    const [selectedDate, setSelectedDate] = useState({
            startDate: "",
            endDate: ""
    });
    
    const getDates = (date:{startDate:string, endDate:string}) => {
        setSelectedDate({ "startDate": date.startDate, "endDate" :  date.endDate})
    }

    useEffect(() => {
        console.log("selectedDate", selectedDate);
        if(selectedDate.startDate !== "") {
            getReservationDataHandler();
        }
        
    }, [searchTerm, selectedDate]);

    const getAllReservationDateWise = async (e: { preventDefault: () => void; }) => {
        setDownloadRead(false);
        e.preventDefault()
        setLoading(true);
        //console.log("searchTerm getUserDataHandler:",searchTerm);
        const response = await getReservationsDateWise(selectedDate);
        console.log(response.data);

        //let headers = ["Creation,Reservation No,Departure,Return,Country,Pick-Up,Drop-Off,Num Days,Travellers,Currency,Total Amount,Net Total Amount,SubAgent Commission,Payment Type,Privilege,Status,Cancellation Date,Company,Username,Booking number,Remarks"]
        
        let dataCsv = response.data.reduce((acc:any, reservation:any) => {
            reservation.status = (reservation.status == "0") ? "Cancelled" : "Reserved"  
          const { reservation_id, first_name, last_name, pickup_location, pickup_oagcode, pickup_datetime, dropoff_location, dropoff_oagcode, dropoff_datetime, country, vendor, reservation_type, payment_type, currency, total_amount, total_rental_amount, no_of_days, pickup_city, pickup_address, dropoff_city, dropoff_address, created_at, status, company, user, reference, customer_remarks, margin, margin_amount, vehicle_group } = reservation
          const company_name = company?.name ?? "";
          const travellers = first_name + ' ' + last_name
          const pick_up =  pickup_oagcode + '-' + pickup_city + '-' + pickup_address
          const drop_off =  dropoff_oagcode + '-' + dropoff_city + '-' + dropoff_address
          const commission = ""
          const userName = user?.name ?? ""
          const totalRentalAmount = total_rental_amount ?? total_amount;
          console.log("customer_remarks",customer_remarks)
          const paymentType = getPaymentTypeName(payment_type, reservation_type);
          const newData = {"Creation": created_at,"Reservation No": reservation_id,"Departure": pickup_datetime,"Return": dropoff_datetime,"Country": country,"Pick-Up": pick_up,"Drop-Off": drop_off, "Num Days":no_of_days, "Travellers":travellers, "Currency":currency,"Total Amount": total_amount,"Net total Amount": totalRentalAmount, "SubAgent Commission":margin_amount, "Commission %":margin,"Payment Type": paymentType, "Privilege":reservation_type, "Status":status,"Company": company_name, "Username": userName, "Booking Reference": reference, "Remarks": customer_remarks,"Group": vehicle_group};
          acc.push(newData)
          return acc
        }, []) 

       console.log("dataCsv",dataCsv);

        
        
        setDownloadData(dataCsv);
        setDownloadRead(true);
        setLoading(false);
    }

    const [downloadReady, setDownloadRead] = useState(false);
    const [downloadData, setDownloadData] = useState([]);


     useEffect(() => {
        if(downloadReady) {
            //@ts-ignore
            document.getElementById("download_xlsx").click();
            //exportToCsv();
        }
        
    }, [downloadData, downloadReady]);

    
    return (
        <>
            <Titlestrip title={"Booking List"} />
            <div className="container">
                <div className="outer-container">
                    {loading ? (

                        <Loader />
                    ) :
                        ""
                    }
                    <>
                        <div className="c-inner-container col-12">
                            <div className="row d-flex flex-wrap justify-content-between align-items-center">
                                <div className="col-12 col-sm-6 d-flex flex-wrap justify-content-sm-end right-container order-sm-1">
                                    <button className="btn btn-export" onClick={getAllReservationDateWise}>Export</button>
                                     {/* <ExcelExportHelper data={downloadData} />   */}  
                                     <div style={{display:"none"}}>
                                        <ExportCSV csvData={downloadData} fileName={"reservations"} />
                                     </div>
                                    
                                    
                                    
                                </div>
                                <div className="col-12 col-sm-6 left-container d-flex flex-wrap justify-content-end justify-content-sm-start order-sm-0">
                                    <div className="left-wrapper">
                                        <span>Show</span>
                                        <select
                                            defaultValue={pageEntries}
                                            onChange={(e) => handlePageEntries(e)}
                                        >
                                            <option value={5}>5</option>
                                            <option value={20}>20</option>
                                            <option value={30}>30</option>
                                            <option value={40}>40</option>
                                            <option value={50}>50</option>
                                        </select>
                                        <span>entries</span>

                                    </div>
                                    <div className="search-box">
                                        <div className="search-icon"></div>
                                        <input
                                            className="form-search"
                                            placeholder="Seach..."
                                            type="text"
                                            name="search"
                                            onChange={(e) => { delayedSearch(e.target.value) }}
                                        />
                                        
                                    </div>
                                  <div className="search-box dateElementHide">
                                        <DateRangePickerComp getDates={getDates} />
                                   </div>
                                   
                                </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                            </div>
                        </div>
                        <button id="closeDatePicker" style={{"display":"none"}}>Close Date Picker</button> 

                        <div className="table-data">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="table-light">
                                        <tr>
                                            {columns.map(({ label, accessor, sortable }) => {
                                                let cls = accessor === sort && order === "asc" ? "desc" : "asc";
                                                if (accessor !== sort) {
                                                    cls = "";
                                                }
                                                return <th key={accessor} className={cls} onClick={() => { if (!sortable) { return; } handleSortingChange(accessor) }}>{label}</th>;
                                            })}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reservations ?
                                            (
                                                reservations.map((reservation: any) => {
                                                    //let rate_name = (user.rate != null) ? user.rate.name : "";
                                                    //let role_name = (user.roles != null) ? user.roles.slug : "";

                                                    //let parent_name = (rate.parent_rate != null) ? rate.parent_rate.name : "";
                                                    var paymentTypeName = "";
                                                    //var paymentTypeListObj = paymentTypeList.find 
                                                    
                                                    if(reservation.payment_type) {
                                                        console.log("reservation.payment_type", reservation.payment_type);
                                                        var payTypeObj = paymentTypeAllList.find((item) => { 
                                                            //console.log(item.code.toLowerCase(),":",reservation.payment_type.toLowerCase());
                                                            return item.code.toLowerCase() == reservation.payment_type.toLowerCase()
                                                        }
                                                        );
                                                        if(payTypeObj) {
                                                            paymentTypeName = payTypeObj?.name;  
                                                        }

                                                        
                                                        
                                                    }
                                                    var createdDate = moment(new Date(reservation.created_at)).format("DD-MM-YYYY") 

                                                    var color = reservation.status == 1 ? "" : "red";
                                                    return (

                                                        <tr key={uuidv4()}>
                                                            <td>{createdDate ?? ""}</td>
                                                            <td>{ typeof reservation?.company === 'object' ?  reservation?.company?.name : reservation?.company}</td>
                                                            <td>{reservation.reservation_id}</td>
                                                            <td>{reservation.first_name}{' '}{reservation.last_name}</td>
                                                            {/*<td>{ moment(new Date(reservation.pickup_date)).format("DD-MM-YYYY") }</td>*/}
                                                            <td>{ moment(reservation.pickup_date, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY") }</td>
                                                            <td>{reservation.pickup_location}{" - "}{reservation.dropoff_location}</td>
                                                            <td>{paymentTypeName}</td>
                                                            <td>{reservation.reservation_type} ({reservation.fare_name})</td>
                                                            <td>{reservation.vehicle_group}</td>
                                                            <td>{reservation.currency}{" "}{reservation.total_amount}</td>
                                                            <td style={{"color": color}}>{reservation.status == 1 ? "Confirmed" : "Cancelled"}</td>
                                                            <td className="edit-container">
                                                                <div className="actions">
                                                                <Link
                                                                    to={`${configRoutes.booking_edit}/${reservation.reservation_id}`}
                                                                >
                                                                    <img
                                                                        src={editIcon}
                                                                        alt="Edit Icon"
                                                                        title="Edit"
                                                                    />
                                                                </Link>
                                                                {
                                                                reservation.status == 1 && 
                                                                    <button className="btn btn-danger" onClick={(e)=> {setVPopup(true);setCancelRerservationId(reservation.reservation_id)}}>Cancel</button>
                                                                }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                )

                                            )
                                            :
                                            ""
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {
                            vPopup ?
                            <CancelBooking closeHandler={closeHandler} reservation_id={cancelRerservationId}  loaderHandler={loaderHandler} />
                            :
                            ""
                        }
                    </>

                    <div className="pagination-container">
                        <Pagination
                            activePage={current_page}
                            itemsCountPerPage={per_page}
                            totalItemsCount={total}
                            pageRangeDisplayed={3}
                            onChange={getReservationDataHandler}
                            itemClass="page-item"
                            linkClass="page-link"
                            prevPageText="Previous"
                            nextPageText="Next"
                            innerClass="pagination justify-content-end"
                            itemClassPrev="noBorder"
                            itemClassNext="noBorder"
                            itemClassFirst="hideElement"
                            itemClassLast="hideElement"
                        />
                    </div>

                    {/* <nav>

          <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={450}
          pageRangeDisplayed={5}
          
        />
            <ul className="pagination justify-content-end">
              <li className="page-item disabled">
                <a className="page-link">Previous</a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  Next
                </a>
              </li>
            </ul>
          </nav> */}
                </div>
            </div>
        </>
    );
};

export default Reservation;
